import React from "react";
import ProductTemplate from "./product";
import NewProductTemplate from "../../components/sections/product-more-info/Item";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    const newProductsEnabled = process.env.GATSBY_NEW_PRODUCTS_ENABLED === "true";

    const Data = () => {
        return (
            <>
                <p>
                    Este libro surge como una herramienta innovadora destinada a facilitar la
                    preparación para la prueba de temario de la oposición al cuerpo de Policía
                    Municipal de Madrid. Su enfoque se centra en quinientas preguntas esenciales del
                    temario, proporcionando una única respuesta para cada una de ellas. La
                    estructura de la obra se caracteriza por ofrecer, además, una ampliación de
                    información detallada en las preguntas consideradas más relevantes.
                </p>

                <p>
                    La organización del libro sigue un criterio cuidadoso, clasificando las
                    preguntas de acuerdo con los temas establecidos en la convocatoria oficial. Esta
                    disposición temática facilita la comprensión y el estudio, permitiendo a los
                    aspirantes abordar de manera efectiva cada área del temario.
                </p>

                <p>
                    Como valor añadido, el libro ofrece a los lectores acceso gratuito durante 6
                    meses al módulo específico en la aplicación iPolicíaMadrid. Esta plataforma
                    complementaria proporciona recursos interactivos y herramientas adicionales para
                    fortalecer la preparación de los candidatos.
                </p>

                <p>
                    En resumen, este libro no solo se presenta como una guía exhaustiva para la
                    preparación de la oposición al cuerpo de Policía Municipal de Madrid, sino que
                    también incorpora elementos tecnológicos para maximizar la eficacia del estudio.
                    Con su enfoque claro y su disposición organizada, se convierte en una
                    herramienta valiosa para aquellos que buscan alcanzar el éxito en esta exigente
                    prueba.
                </p>

                <div>
                    <a
                        href={require("@static/documents/products/523/preview2.pdf")}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Descargar vista previa
                    </a>
                    <br />
                    <br />

                    <div className="rounded p-5 border shadow-md mt-5">
                        <embed
                            src={require("@static/documents/products/523/preview2.pdf")}
                            type="application/pdf"
                            className=""
                            width="100%"
                            height="750px"
                        />
                    </div>
                </div>
            </>
        );
    };

    return (
        <>
            {!newProductsEnabled ? (
                <ProductTemplate item={item}>
                    <Data />
                </ProductTemplate>
            ) : (
                <NewProductTemplate
                    item={item}
                    title="Prepárate para la oposición a Policía Municipal de Madrid con 500 preguntas clave, explicaciones ampliadas y acceso gratuito a iPolicíaMadrid. Todo lo que necesitas para alcanzar tu objetivo, en un solo libro. ¡Da el primer paso hacia tu futuro!"
                >
                    <Data />
                </NewProductTemplate>
            )}
        </>
    );
};
export default Product;
